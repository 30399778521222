import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Banner,
  BannerList,
  Button,
  renderErrorToast,
  ReviewNewExternalPatientRecord,
  SectionTitle,
} from "~/components/design-system/index.js";
import { api } from "../../api.js";
import { DefaultPageProps } from "../defaultProps.js";

import {
  BasePatientDemographics,
  ExternalPatientLink,
  ExternalPatientLinkEnhanced,
} from "@aspire/common";
import { NhsNumberSchema } from "@thalamos/common";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { useSyncExternalPatientLink } from "~/hooks/ExternalPatientLink/common/syncExternalPatientLink.js";
import { routeFns } from "~/routes.js";
import { logActionToDatadog, logErrorToDatadog } from "~/tracing.js";

// Holds the state for this page.
type State =
  | {
      // Something has gone wrong and needs presenting to the user.
      type: "error";
      error: string;
    }
  | {
      // The patient details were not found on the external system.
      type: "not-found";
    }
  | {
      // The patient already exists in eMHA, and we need to review their details.
      type: "externalPatientLink";
      externalPatientLink: ExternalPatientLink | undefined;
      rootPatientId: string;
    }
  | {
      // The patient already exists in eMHA, but they have been merged into another patient,
      // and this link is not the higest priority.
      type: "mergedExternalPatientLink";
      rootPatientId: string;
    }
  | {
      // The patient does not exist in eMHA, and we need to create them.
      type: "newPatient";
      demographics: BasePatientDemographics & {
        nhsNumber?: string;
      };
      externalSystemType: ExternalPatientLinkEnhanced["externalSystemType"];
      externalSystemId: ExternalPatientLinkEnhanced["externalSystemId"];
      externalPatientIdType: ExternalPatientLinkEnhanced["externalPatientIdType"];
      externalPatientIdValue: ExternalPatientLinkEnhanced["externalPatientIdValue"];
    };

export function PatientContextLaunchPage({}: DefaultPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [creatingPatient, setCreatingPatient] = useState(false);

  // Extract the query params
  const { search } = useLocation();
  const qs = new URLSearchParams(search);

  const token = qs.get("token");

  // Only run this check once - if the token is missing after initial component
  // mount then it is probably due to a navigation race condition as the user
  // leaves the page
  useEffect(() => {
    if (!token) {
      throw new Error(
        "Rio token missing from URL - please try your search / clickthrough again",
      );
    }
  }, []);

  const [state, setState] = useState<State | undefined>(undefined);

  // We are managing at most one external patient link
  const {
    Component: ExternalPatientLinkComponent,
    componentProps: externalPatientLinkComponentProps,
    syncExternalPatientLink,
  } = useSyncExternalPatientLink(
    state?.type === "externalPatientLink"
      ? state.externalPatientLink
      : undefined,
  );

  // Look for an existing patient with this link
  useEffect(() => {
    const doEffect = async () => {
      if (token) {
        // Redeem the token to get the external system details
        const redeemTokenResponse = await api.rio.clickThrough.redeemToken({
          token: token,
        });

        if (redeemTokenResponse.status !== 200) {
          logActionToDatadog(
            "PatientContextLaunchPage - failed to redeem token",
            {
              token,
              status: redeemTokenResponse.status,
              data: redeemTokenResponse.data,
            },
          );

          setState({
            type: "error",
            error:
              "Failed to complete Rio operation - please try your search / clickthrough again from the start",
          });
          return;
        }

        const {
          externalSystemId,
          externalSystemType,
          externalPatientIdType,
          externalPatientIdValue,
        } = redeemTokenResponse.data;

        // Get the patient details from the external system
        // by attemting a create with confirmCreate = false
        const res = await api.patients.createFromExternalId({
          externalSystemType,
          externalSystemId,
          externalPatientIdType,
          externalPatientIdValue,
        });

        if (res.status === 200) {
          if (res.data.result === "new-patient") {
            logActionToDatadog(
              "PatientContextLaunchPage - new patient shown for review",
            );
            setState({
              type: "newPatient",
              demographics: res.data.demographics,
              externalSystemType,
              externalSystemId,
              externalPatientIdType,
              externalPatientIdValue,
            });
          } else if (res.data.result === "existing-patient") {
            logActionToDatadog("PatientContextLaunchPage - existing patient");

            if (res.data.isHighestPriorityNhsNumber) {
              setState({
                type: "externalPatientLink",
                externalPatientLink:
                  res.data.existingPatient.externalLinks!.find(
                    (l) =>
                      l.externalSystemId === externalSystemId &&
                      l.externalSystemType === externalSystemType &&
                      l.externalPatientIdValue === externalPatientIdValue,
                  ),
                rootPatientId: res.data.existingPatient.id,
              });
            } else {
              setState({
                type: "mergedExternalPatientLink",
                rootPatientId: res.data.existingPatient.id,
              });
            }
          }
        } else if (res.status === 404) {
          logActionToDatadog("PatientContextLaunchPage - patient not found");
          setState({ type: "not-found" });
        } else {
          logErrorToDatadog(
            new Error(
              "PatientContextLaunchPage - failed to fetch patient from Rio",
            ),
          );
          setState({
            type: "error",
            error:
              (res.data as any).reason ??
              "Failed to fetch patient details from external system",
          });
        }
      }
    };

    doEffect();
  }, [token]);

  // If we have an existing link, do a pull and review
  useEffect(() => {
    const doEffect = async () => {
      if (state?.type === "externalPatientLink") {
        // If we have permission to pull demographics from this link, then
        // attempt to do so.
        if (state.externalPatientLink) {
          // All of the interaction with the user is handled by this method.
          // When the interaction is complete, then the promise completes.
          await syncExternalPatientLink("click through");
        }

        // Navigate to the patient page
        navigate(routeFns.patientHome(state.rootPatientId), { replace: true });
      }
    };

    doEffect();
  }, [state, syncExternalPatientLink, navigate]);

  // If it is a new patient we will need to let them review the details.
  // We can't re-use the code within syncExternalPatientLink() because the
  // user may reject the patient details, which results in no eMHA patient
  // record or patient link being created.
  const patientRecords = useMemo(
    () =>
      state?.type === "newPatient"
        ? {
            Rio: state.demographics,
          }
        : undefined,
    [state],
  );

  const NHSNumber = useMemo(
    () =>
      state?.type === "newPatient" ? state.demographics.nhsNumber : undefined,
    [state],
  );

  // Create this id outside of the handler so that multiple clicks of
  // the button do not result in multiple links being created.
  const newExternalPatientLinkId = useMemo(() => v4(), []);

  // This is called when the user confirms the review dialog
  const onConfirm = useCallback(async () => {
    if (!token || state?.type !== "newPatient") {
      return false;
    }
    setCreatingPatient(true);
    try {
      // Call the create API again, confirming the creation of the brand
      // new patient
      const result = await api.patients.createFromExternalId({
        externalSystemType: state.externalSystemType,
        externalSystemId: state.externalSystemId,
        externalPatientIdType: state.externalPatientIdType,
        externalPatientIdValue: state.externalPatientIdValue,
        confirmCreate: true,
      });

      if (result.status !== 200 || result.data.result !== "patient-created") {
        renderErrorToast({
          message: t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.toast.failedToCreatePatient",
          ),
        });
        return false;
      }

      logActionToDatadog(
        "PatientContextLaunchPage - patient created from external system",
        {
          patientId: result.data.createdPatient.id,
        },
      );

      // Navigate to the patient page`
      navigate(routeFns.patientHome(result.data.createdPatient.id), {
        replace: true,
      });

      return true;
    } finally {
      // However we terminate, we should mark the patient as not being created
      setCreatingPatient(false);
    }
  }, [navigate, newExternalPatientLinkId, state, t, token]);

  // This is called when the user cancels the review dialog
  const onCancel = useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  const titleText = t(
    state?.type === "mergedExternalPatientLink"
      ? "components.externalPatientLink.reviewNewExternalPatientRecord.title.mergedExternalPatientLink"
      : state?.type === "externalPatientLink"
        ? "components.externalPatientLink.reviewNewExternalPatientRecord.title.externalPatientLink"
        : state?.type === "newPatient"
          ? "components.externalPatientLink.reviewNewExternalPatientRecord.title.newPatient"
          : "components.externalPatientLink.reviewNewExternalPatientRecord.title.contacting",
  );

  const isLoading = state === undefined;

  return isLoading ? (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Stack gap="1rem">
      <ExternalPatientLinkComponent {...externalPatientLinkComponentProps} />
      <SectionTitle
        titleText={titleText}
        subtitleText={
          NHSNumber
            ? `NHS Number: ${NhsNumberSchema.catch(t("common.unknown")).parse(NHSNumber)}`
            : undefined
        }
      ></SectionTitle>
      {state?.type === "error" ? (
        <Banner bannerType={BannerList.ERROR} title={state.error} />
      ) : state?.type === "not-found" ? (
        <Banner
          bannerType={BannerList.ERROR}
          body={t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.banner.notFound",
          ).split("\n")}
        />
      ) : state?.type === "mergedExternalPatientLink" ? (
        <Banner
          bannerType={BannerList.WARNING}
          body={t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.banner.mergedExternalPatientLink",
          ).split("\n")}
        />
      ) : state?.type === "newPatient" ? (
        <Banner
          bannerType={BannerList.INFO}
          body={t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.banner.newPatient",
          ).split("\n")}
        />
      ) : null}
      {patientRecords !== undefined && (
        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
          <ReviewNewExternalPatientRecord
            disabled={creatingPatient}
            patientRecords={patientRecords}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      )}
      {state?.type === "mergedExternalPatientLink" && (
        <Stack direction="row" justifyContent={"center"}>
          <Button
            label={t("buttonLabels.close")}
            testId="close-button"
            onClick={() => {
              navigate(routeFns.patientHome(state.rootPatientId), {
                replace: true,
              });
            }}
          />
        </Stack>
      )}
      {state?.type === "not-found" && (
        <Stack direction="row" justifyContent={"center"}>
          <Button
            label={t("buttonLabels.backSearch")}
            testId="close-button"
            onClick={() => {
              navigate(routeFns.patientSearch(), { replace: true });
            }}
          />
        </Stack>
      )}
      {state?.type === "error" && (
        <Stack direction="row" justifyContent={"center"}>
          <Button
            label={t("buttonLabels.home")}
            testId="close-button"
            onClick={() => {
              navigate(routeFns.home(), { replace: true });
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}
