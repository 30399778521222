import {
  CreateWorkItemAction,
  FormContextData,
  nonEmptyString,
  Team,
  UserOrTeamSearchResult,
  UserSearchResult,
  UUID,
} from "@aspire/common";
import { Avatar, Box, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import debounce from "lodash-es/debounce.js";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router";
import { array, boolean, InferType, object, string } from "yup";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection.js";
import {
  AddRecipientButton,
  handleAddRecipient,
  mapGuestAccreditation,
  RecipientsList,
  SendRequestButton,
  shareToNonTrainingUserFromTrainingFn,
  shareToTrainingUserFromNonTrainingFn,
  TrainingWarningBanners,
  userNotSelectedFn,
} from "~/pages/FormProgressPage/helpers/RequestWorkItemDialog.js";
import { Banner, BannerList } from "../design-system/Banner/Banner.js";
import { Dropdown } from "../design-system/Dropdown/Dropdown.js";
import { TextField } from "../design-system/TextField/TextField.js";
import { Typeahead } from "../design-system/Typeahead/Typeahead.js";
import { stringAvatar } from "../layout/menu/helpers/index.js";

export interface NewResultWithoutAccreditation
  extends Omit<Result, "guestInviteAccreditation"> {}

export type Result = {
  teamId: string;
  teamType: Team["type"];
  email: string;
  name: string;
  contexts?: UserSearchResult["contexts"];
  assignedUserId?: string;
  guestInviteAccreditation: string;
  isTrainingOrganisation: boolean;
  entityType: "team" | "user";
};

export const newFormAssigneeData = object({
  type: string().oneOf(["full-user", "guest"]),
  teamId: string(),
  assignedUserId: string(),
  email: nonEmptyString
    .email("Please enter a valid email")
    .required("Please enter an email"),
  name: nonEmptyString.required("Please enter a name"),
  guestInviteAccreditation: nonEmptyString.required(
    "Please confirm who you are sending this invite to",
  ),
  isTrainingOrganisation: boolean(),
  selectedTeam: string().when("userMembershipContext", {
    is: (contexts: UserSearchResult["contexts"]) => contexts.length > 1,
    then: (schema) => schema.required("Please select a team"),
    otherwise: (schema) => schema.optional(),
  }),
  userMembershipContext: array()
    .of(
      object({
        id: string(),
        email: string(),
        name: string(),
        role: string(),
        teamType: string(),
        isTrainingOrganisation: boolean(),
      }),
    )
    .default([]),

  teamName: string(),
  entityType: string(),
  teamType: string(),
  action: string().optional(),
});

export function newExpandSearchResults(
  results: UserOrTeamSearchResult[],
): NewResultWithoutAccreditation[] {
  const expandedResults: NewResultWithoutAccreditation[] = [];
  for (const result of results) {
    if (result.type === "team") {
      expandedResults.push({
        teamType: result.teamType,
        teamId: result.id,
        email: result.email,
        name: result.name,
        isTrainingOrganisation: !!result.isTrainingOrganisation,
        entityType: result.type,
      });
    } else {
      // We can set the team type to null for now if theres more than one team associated to the
      // user as it we set it later when we select a team from the dropdown
      const removeManagerContext = result.contexts.filter(
        (m) => m.role !== "manager",
      );
      const oneContextResult = removeManagerContext.length === 1;
      expandedResults.push({
        teamId: oneContextResult ? result.contexts[0].id : "",
        email: result.email,
        teamType: result.contexts[0]?.teamType,
        contexts: result.contexts,
        name: result.name,
        assignedUserId: result.id,
        isTrainingOrganisation: result.contexts[0]?.isTrainingOrganisation,
        entityType: result.type,
      });
    }
  }

  return expandedResults;
}

export type FormDetails = {
  template: { id: string; version: string };
  part: number;
};

export type FormikNewFormAssigneeValues = {
  values: InferType<typeof newFormAssigneeData>;
  isSubmitting: boolean;
  submitForm: () => void;
  setValues: (values: any) => void;
  setTouched: (touched: any) => void;
};

export type FormikInitialValues = {
  type: string;
  teamId: string;
  email: string;
  name: string;
  guestInviteAccreditation: {};
  isTrainingOrganisation: boolean;
  selectedTeam: string;
};

export interface NewFormAssigneeDialogProps {
  formId: string | undefined;
  formContext: Omit<FormContextData, "patient">;
  reloadFormContext: () => void;
  availableActions: (CreateWorkItemAction | { type: "share" })[];
  formDetails: FormDetails;
  userId: string;
  onSave: (formData: InferType<typeof newFormAssigneeData>) => Promise<void>;
  searchAssignmentOptions: (
    formDetails: FormDetails,
    query: string,
  ) => Promise<{ data: { results: UserOrTeamSearchResult[] } }>;
  guestMessage?: string;
  guestBannerType?: BannerList;
  setInviteSuccessMessageName?: (params: any) => void;
  guestAccreditationOptions?: { label: string; value: string }[];
  searchResults: NewResultWithoutAccreditation[];
  setSearchResults: React.Dispatch<
    React.SetStateAction<NewResultWithoutAccreditation[]>
  >;
  navigate: NavigateFunction;
  signForm?: () => void;
}

function FormikInner({
  formId,
  formContext,
  reloadFormContext,
  availableActions,
  navigate,
  userId,
  formDetails,
  searchAssignmentOptions,
  searchResults,
  setSearchResults,
  onSave,
  guestMessage,
  guestBannerType,
  setInviteSuccessMessageName,
  defaultGuestAccreditationValue,
  defaultedGuestAccreditationOptions,
  signForm,
  formikValues,
  initialValues,
}: NewFormAssigneeDialogProps & {
  formikValues: FormikProps<InferType<typeof newFormAssigneeData>>;
  initialValues: InferType<typeof newFormAssigneeData>;
  defaultGuestAccreditationValue: string;
  defaultedGuestAccreditationOptions: { label: string; value: string }[];
}) {
  const { values, setValues, setTouched, touched, errors, setErrors } =
    formikValues;
  const { t } = useTranslation();

  const emailRegex = new RegExp(
    "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
  );

  const isTeamSelectorDisabled = values.userMembershipContext.length <= 1;

  const isTeamEntityType = values.entityType === "team";

  const isGuestType = values.type === "guest";

  const userMembershipContextOptions =
    values.userMembershipContext.length > 0
      ? values.userMembershipContext
          .filter((m) => m.role === "member")
          .map((context) => {
            const teamType = context.teamType
              ? context.teamType.charAt(0).toUpperCase() +
                context.teamType.slice(1)
              : "";
            return {
              label: `${context.teamType === "independent" ? `${teamType}` : `${context.name}`}`,
              value: context.email,
            };
          })
      : isTeamEntityType
        ? [
            {
              label: values.name,
              value: values.email,
            },
          ]
        : isGuestType
          ? [{ label: "Guest", value: values.email }]
          : [];

  const removeManagerContext = values.userMembershipContext.filter(
    (m) => m.role !== "manager",
  );

  const selectedValue =
    removeManagerContext.length === 1
      ? removeManagerContext[0].email
      : removeManagerContext.length === 0 || isGuestType
        ? values.email
        : values.selectedTeam;

  const isSelectedUserWorkingIndependently =
    values.email === selectedValue && values.teamType === "independent";

  const search = useCallback(async (value: string) => {
    const results = await searchAssignmentOptions(formDetails, value);

    const expandedResults = newExpandSearchResults(results.data.results);

    setSearchResults(
      expandedResults.filter((u) => u.assignedUserId !== userId),
    );

    const match = expandedResults.find((u) => value.toLowerCase() === u.email);

    if (match) {
      setInviteSuccessMessageName?.(match);
      if (match.teamType === "guest") {
        setValues({
          type: "guest",
          email: match.email,
          name: match.name,
          userMembershipContext: [],
          guestInviteAccreditation: "",
        });
      } else {
        setValues({
          type: "full-user",
          userMembershipContext: match.contexts ?? [],
          guestInviteAccreditation: "",
          ...match,
        });
      }
    }
  }, []);

  const debouncedSearch = useCallback(debounce(search, 100), [search]);
  return (
    <>
      <Box>
        <Box>
          <Typeahead
            enableFuse={false}
            testId="form-assignee-email"
            label={t("pages.formDraftSignPage.typeaheadLabel")}
            options={searchResults}
            name="recipientEmail"
            onBlur={() => {
              setTouched({ ...touched, email: true });
            }}
            errorMessage={touched.email ? (errors.email as string) : ""}
            showHelperText={!!errors.email}
            inputValue={values.email}
            optionsKey="name"
            renderOption={(props, option) => {
              const subOptionToShow = () => {
                if (option?.contexts?.length && option?.contexts?.length > 1) {
                  const filteredContexts = option.contexts.filter(
                    (context) => context.role !== "manager",
                  );
                  return `${filteredContexts.length} team memberships`;
                }

                return option.email;
              };
              const hasOneTeamShow = () => {
                const filteredContexts = option?.contexts?.filter(
                  (context) => context.role !== "manager",
                );
                if (
                  !!filteredContexts?.length &&
                  filteredContexts?.length === 1 &&
                  filteredContexts[0].teamType !== "independent"
                ) {
                  return `${filteredContexts[0].name}`;
                }
              };

              return (
                <li {...props}>
                  <Box display="flex" alignItems="center">
                    <Avatar
                      sx={{ mr: 2, backgroundColor: "primary.main" }}
                      {...stringAvatar(option.name)}
                    />
                    <Box display="flex" flexDirection={"column"}>
                      <Box display="flex" flexDirection={"row"}>
                        <Typography>{option.name}</Typography>
                        {hasOneTeamShow() && (
                          <Typography sx={{ ml: 1 }}>
                            ({hasOneTeamShow()})
                          </Typography>
                        )}
                      </Box>
                      <Typography>{subOptionToShow()}</Typography>
                    </Box>
                  </Box>
                </li>
              );
            }}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : `${option.name} (${option.email})`
            }
            onInputChange={async (value: string) => {
              const match = searchResults.find(
                (u) => value === `${u.name} (${u.email})`,
              );

              if (match) {
                const userContexts = match.contexts ?? [];
                setInviteSuccessMessageName?.(match?.name);
                if (match.teamType === "guest") {
                  setValues({
                    type: "guest",
                    email: match.email,
                    name: match.name,
                    userMembershipContext: [],
                    guestInviteAccreditation: defaultGuestAccreditationValue,
                  });
                } else {
                  setValues({
                    type: "full-user",
                    userMembershipContext:
                      userContexts.length > 0 ? userContexts : [],
                    guestInviteAccreditation: defaultGuestAccreditationValue,
                    ...match,
                  });
                }
              } else {
                setInviteSuccessMessageName?.(value);
                setValues({
                  ...initialValues,
                  email: value,
                  type: value?.match(emailRegex) ? "guest" : "full-user",
                });
              }
              // Only search when we have at least 3 characters
              if (value.length > 2) {
                debouncedSearch(value);
              } else {
                setSearchResults([]);
              }
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box sx={{ minWidth: 350 }}>
            <Dropdown
              subtext={
                isSelectedUserWorkingIndependently
                  ? "Person is working independently"
                  : ""
              }
              label={"Please select a team"}
              selectedValue={selectedValue}
              disabled={
                isTeamSelectorDisabled ||
                values.email === "" ||
                removeManagerContext.length === 1
              }
              name={"invite"}
              values={userMembershipContextOptions.sort((a, b) =>
                a.label.localeCompare(b.label),
              )}
              onChange={(teamEmail) => {
                const userMembershipContextMatch =
                  values.userMembershipContext.find(
                    (m) => m.role === "member" && m.email === teamEmail,
                  );
                if (
                  userMembershipContextMatch &&
                  userMembershipContextMatch.email
                ) {
                  setValues({
                    ...values,
                    isTrainingOrganisation:
                      userMembershipContextMatch.isTrainingOrganisation,
                    name: values.name,
                    selectedTeam: teamEmail,
                    teamType: userMembershipContextMatch.teamType,
                    teamId: userMembershipContextMatch.id,
                  });
                }
              }}
            />
          </Box>
        </Box>
        <Box>
          {values.type === "guest" && (
            <>
              <Box sx={{ mt: 0.5, mb: 4 }}>
                <Box sx={{ mb: 0.5 }}>
                  <TextField
                    useFullWidth={true}
                    value={values.name}
                    onBlur={() => {
                      setTouched({ ...touched, name: true });
                    }}
                    showHelperText={!!errors.name}
                    errorMessage={touched.name ? (errors.name as string) : ""}
                    name={"name"}
                    label={"Name"}
                    onChange={(e) => setValues({ ...values, name: e })}
                  />
                </Box>
                <Banner
                  bannerType={guestBannerType || BannerList.INFO}
                  title={
                    guestMessage ||
                    t("components.newFormAssignee.guestUserClarification")
                  }
                />
              </Box>

              {defaultedGuestAccreditationOptions.length > 1 && (
                <Box sx={{ my: 4 }}>
                  <Dropdown
                    label={"Please confirm who you are sending this invite to"}
                    selectedValue={values.guestInviteAccreditation}
                    name={"invite"}
                    values={defaultedGuestAccreditationOptions}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        guestInviteAccreditation: e,
                      });
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <SubmitComponentFn
        formContext={formContext}
        formikValues={{
          ...formikValues,
          values: {
            ...formikValues.values,
            email: formikValues.values.email.toLowerCase(),
            name: formikValues.values.name.trim(),
          },
        }}
        availableActions={availableActions}
        formId={formId}
        initialValues={initialValues as FormikInitialValues}
        reloadFormContext={reloadFormContext}
        navigate={navigate}
        signForm={signForm}
      />
    </>
  );
}

export function NewFormAssigneeDialog(props: NewFormAssigneeDialogProps) {
  const defaultedGuestAccreditationOptions = !props.guestAccreditationOptions
    ?.length
    ? [
        {
          label: "Other",
          value: "other",
        },
      ]
    : props.guestAccreditationOptions;

  const defaultGuestAccreditationValue =
    defaultedGuestAccreditationOptions.length > 1
      ? ""
      : defaultedGuestAccreditationOptions?.[0].value;

  const initialValues = {
    type: "full-user",
    teamId: "",
    email: "",
    name: "",
    guestInviteAccreditation: defaultGuestAccreditationValue,
    isTrainingOrganisation: false,
    selectedTeam: "",
    userMembershipContext: [],
  } satisfies InferType<typeof newFormAssigneeData>;

  return (
    <Formik<InferType<typeof newFormAssigneeData>>
      validationSchema={newFormAssigneeData}
      initialValues={initialValues}
      onSubmit={props.onSave}
    >
      {(formikValues) => {
        return (
          <FormikInner
            {...props}
            defaultGuestAccreditationValue={defaultGuestAccreditationValue}
            defaultedGuestAccreditationOptions={
              defaultedGuestAccreditationOptions
            }
            formikValues={formikValues}
            initialValues={initialValues}
          />
        );
      }}
    </Formik>
  );
}

export function SubmitComponentFn({
  formContext,
  formikValues,
  availableActions,
  formId,
  initialValues,
  reloadFormContext,
  navigate,
  signForm,
}: {
  formContext: Omit<FormContextData, "patient">;
  formikValues: FormikNewFormAssigneeValues;
  availableActions: (CreateWorkItemAction | { type: "share" })[];
  formId: string | undefined;
  initialValues: FormikInitialValues;
  reloadFormContext: () => void;
  navigate: (path: string) => void;
  signForm?: () => void;
}) {
  const [recipients, setRecipients] = useState<
    InferType<typeof newFormAssigneeData>[]
  >([]);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const isTrainingContext = formContext.isTrainingFormContext;
  const { isMobileView } = useScreenDetection();
  const guestInviteAccreditation = {
    type: mapGuestAccreditation(
      formContext,
      formikValues.values.guestInviteAccreditation,
    ),
  } as CreateWorkItemAction | { type: "other" };

  const userNotSelected = userNotSelectedFn(formikValues);

  const shareToNonTrainingUserFromTraining =
    shareToNonTrainingUserFromTrainingFn(
      formikValues,
      userNotSelected,
      isTrainingContext,
    );

  const shareToTrainingUserFromNonTraining =
    shareToTrainingUserFromNonTrainingFn(
      formikValues,
      userNotSelected,
      isTrainingContext,
    );

  const canAddRecipient = !(
    userNotSelected ||
    formikValues.isSubmitting ||
    shareToNonTrainingUserFromTraining ||
    shareToTrainingUserFromNonTraining
  );

  return (
    <Box>
      {submitError && (
        <Box sx={{ mb: 4 }}>
          <Banner title={submitError} bannerType={BannerList.ERROR} />
        </Box>
      )}

      <TrainingWarningBanners
        shareToNonTrainingUserFromTraining={shareToNonTrainingUserFromTraining}
        shareToTrainingUserFromNonTraining={shareToTrainingUserFromNonTraining}
      />

      <AddRecipientButton
        disabled={!canAddRecipient}
        onClick={async () => {
          setSubmitError(null);
          const action =
            guestInviteAccreditation.type &&
            guestInviteAccreditation.type !== "other"
              ? guestInviteAccreditation.type === "complete"
                ? (availableActions.find(
                    (a) => a.type === "complete" && a.formId === formId,
                  ) as {
                    type: "complete";
                    formId: UUID;
                    formPart: number;
                    formVersion: number;
                  })!
                : guestInviteAccreditation
              : undefined;

          return handleAddRecipient({
            formikValues,
            setRecipients,
            initialValues,
            action,
          });
        }}
      />
      <RecipientsList
        recipients={recipients}
        isMobileView={isMobileView}
        setRecipients={setRecipients}
        setSubmitError={setSubmitError}
      />
      <SendRequestButton
        navigate={navigate}
        shareToNonTrainingUserFromTraining={shareToNonTrainingUserFromTraining}
        shareToTrainingUserFromNonTraining={shareToTrainingUserFromNonTraining}
        recipients={recipients}
        setSubmitError={setSubmitError}
        formContextId={formContext.id}
        reloadFormContext={reloadFormContext}
        patientId={formContext.patientId}
        signForm={signForm}
        canAddRecipient={canAddRecipient}
      />
    </Box>
  );
}
