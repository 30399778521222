import {
  AcceptTermsAndConditionsRequest,
  AddFileNote,
  AddOrganisationMemberRequest,
  AddTeamMemberRequest,
  AuditTrailEntry,
  AuditTrailEntryType,
  CreateAndClaimOneTimeTokenForRioClickThroughInstanceRequest,
  CreateAndClaimOneTimeTokenForRioClickThroughInstanceResponse,
  CreateDraft,
  CreateExternalPatientDemograhicsPullEventRequest,
  CreateExternalPatientDemograhicsPullEventResponse,
  CreateFormRequest,
  CreateOrganisationRequest,
  CreatePatientBulkImportTokenRequest,
  CreatePatientFromExternalIdRequest,
  CreatePatientFromExternalIdResponse,
  CreatePatientRequest,
  CreateTeamRequest,
  CreateTermsAndConditionsRequest,
  CreateWorkItemRequest,
  DashboardData,
  DataSharingPartnership,
  DataSharingPartnershipWithDataControllers,
  DeleteOrganisationRequest,
  DeleteTeamRequest,
  ExtendedOrganisation,
  ExtendedPatient,
  ExtendedPatientBulkImportToken,
  ExtendedTeam,
  ExtendedThalamosUser,
  FileNote,
  FormContextData,
  FormPartAssignmentOptionsSearch,
  FormStatus,
  GeneratePresignedUrlRequestBody,
  GeneratePresignedUrlResponseBody,
  GetAllPatientBulkImportTokensResponse,
  GetExternalPatientDemograhicsPullEventResponse,
  GetExternalPatientFormPushEventResponse,
  GetExternalPatientLinksResponse,
  GetFormDraftResponse,
  GetFormResponse,
  GetOrganisationResponse,
  GetPatientMhaStateResponse,
  GetPatientResponse,
  GetWorkResponse,
  GuestUserConversionRequest,
  guestUserLoginHint,
  ManagedLocation,
  Membership,
  MhaDashboardDataParams,
  MhaDashboardDataResponse,
  notificationChannelFns,
  NotificationEvents,
  NotificationsResponse,
  Organisation,
  OrganisationConfiguration,
  OrgsAndTeamsResult,
  Patient,
  PatientFormsResponse,
  PatientIndexQuery,
  PatientIndexSearchResult,
  PatientMergeInfo,
  PatientStateWithContext,
  PatientTimelineResponse,
  PatientUnmergeIds,
  RedeemOneTimeTokenForRioClickThroughInstanceRequest,
  RedeemOneTimeTokenForRioClickThroughInstanceResponse,
  RemoveOrganisationMemberRequest,
  RemoveTeamMemberRequest,
  ReportContextParams,
  RequestAmendRequest,
  RioConfiguration,
  RioUploadData,
  SearchReasoning,
  SignDraftRequest,
  SignedFormsCsvParams,
  TemplateData,
  TermsAndConditions,
  TermsAndConditionsResponse,
  UpdateExternalPatientDemograhicsPullEventRequest,
  UpdateExternalPatientDemograhicsPullEventResponse,
  UpdateOrganisationRequest,
  UpdatePatientRequest,
  UpdatePatientStateWithManualEventRequest,
  UpdateTeamRequest,
  UploadFormRequest,
  UpsertRioInstanceRequest,
  UsePatientBulkImportTokenResponse,
  UserCreate,
  UserOrTeamSearchResult,
  UserUpdate,
  UUID,
} from "@aspire/common";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  makeUseAxios,
  Options,
  RefetchFunction,
  UseAxiosResult,
} from "axios-hooks";
import { debounce } from "lodash-es";
import { config } from "./config.js";
import { useChannel } from "./lib/pusher/useChannel.js";
import { useEvent } from "./lib/pusher/useEvent.js";
import { routeFns } from "./routes.js";

let currentUserId: string | null = null;

const apiAxios = axios.create({
  baseURL: config.backendUrl,

  // Important for cookies etc.
  withCredentials: true,
  validateStatus: (status) => status >= 200 && status < 500,
});

apiAxios.interceptors.request.use((config) => {
  if (
    currentUserId &&
    ["get", "post", "put", "delete", "patch"].includes(
      config.method?.toLowerCase() || "",
    )
  ) {
    config.headers!["X-Thalamos-User-Id"] = currentUserId;
  }
  return config;
});

export const redirectToLogin = () => {
  const pathname = window.location.pathname;
  const originalQs = new URLSearchParams(window.location.search);

  const returnTo = `${pathname}${
    originalQs.size > 0 ? `?${originalQs.toString()}` : ""
  }`;

  window.location.href = routeFns.loginPage(returnTo);
};

apiAxios.interceptors.response.use((response) => {
  if (response.status === 401) {
    const hintedGuestUserId = response?.headers?.[guestUserLoginHint];

    if (hintedGuestUserId) {
      window.location.href = routeFns.guestUserLogin(
        hintedGuestUserId,
        window.location.pathname,
      );
    } else if (window.location.pathname === "/login-failed") {
      logout();
    } else {
      redirectToLogin();
    }
  }
  return response;
});

const useApiAxios = makeUseAxios({ axios: apiAxios, cache: false });

export const apiConfig = {
  patientBulkImport: {
    get: (id: UUID) => ({
      url: `/api/patient-bulk-import/${id}`,
      method: "get",
    }),
    getAll: () => ({
      url: "/api/patient-bulk-import",
      method: "get",
    }),
    create: (id: UUID, body: CreatePatientBulkImportTokenRequest) => ({
      url: `/api/patient-bulk-import/${id}`,
      method: "put",
      data: body,
    }),
    revoke: (id: UUID) => ({
      url: `/api/patient-bulk-import/${id}/revoke`,
      method: "post",
    }),
    use: (id: UUID, csv: string, dryRun: boolean) => ({
      url: `/api/patient-bulk-import/${id}/use`,
      method: "post",
      params: { dryRun },
      data: { csv },
    }),
  },
  system: {
    generatePresignedUploadUrl: (body: GeneratePresignedUrlRequestBody) => ({
      url: `/api/system/generate-presigned-url`,
      method: "post",
      data: body,
    }),
  },
  termsAndConditions: {
    getAll: () => ({
      url: "/api/system/terms-and-conditions",
      method: "get",
    }),
    get: (id: UUID) => ({
      url: `/api/system/terms-and-conditions/${id}`,
      method: "get",
    }),
    create: (id: UUID, req: CreateTermsAndConditionsRequest) => ({
      url: `/api/system/terms-and-conditions/${id}`,
      method: "put",
      data: req,
    }),
    accept: (req: AcceptTermsAndConditionsRequest) => ({
      url: `/api/user/accept-terms-and-conditions`,
      method: "post",
      data: req,
    }),
  },
  admissions: {
    recordReasonForNotProceedingMedRec: (
      itemId: string,
      reason: string,
      reasonDescription?: string,
    ) => ({
      url: `/api/admission/${itemId}/record-reason-for-not-proceeding-med-rec`,
      method: "post",
      params: { reason, reasonDescription },
    }),
    recordReasonForNotProceedingAppForm: (
      itemId: string,
      reason: string,
      reasonDescription?: string,
    ) => ({
      url: `/api/admission/${itemId}/record-reason-for-not-proceeding-app-form`,
      method: "post",
      params: { reason, reasonDescription },
    }),
    reOpenAdmission: (
      itemId: string,
      reason: string,
      reasonDescription?: string,
    ) => ({
      url: `/api/admission/${itemId}/reopen`,
      method: "post",
      params: { reason, reasonDescription },
    }),
  },
  reports: {
    availableOrgsAndTeams: (params: { teamId?: string; orgId?: string }) => ({
      url: "/api/reports/available-orgs-and-teams",
      method: "GET",
      params,
    }),
    generateSignedFormsCsv: (params: SignedFormsCsvParams) => ({
      url: "/api/reports/signed-forms",
      method: "POST",
      data: params,
    }),
    getDashboardData: (params: ReportContextParams) => ({
      url: "/api/reports/dashboard-data",
      method: "POST",
      data: params,
    }),
  },
  configuration: {
    getLocations: () => ({
      url: "/api/configuration/locations",
      method: "GET",
    }),
    addLocation: (location: Omit<ManagedLocation, "id" | "status">) => ({
      url: "/api/configuration/locations/add",
      method: "POST",
      data: location,
    }),
    updateLocation: (
      id: string,
      location: Omit<ManagedLocation, "id" | "status">,
    ) => ({
      url: `/api/configuration/locations/update/${id}`,
      method: "patch",
      data: location,
    }),
    deleteLocation: (id: UUID) => ({
      url: `/api/configuration/locations/delete/${id}`,
      method: "POST",
    }),
    getApprovedEmailDomains: () => ({
      url: "/api/configuration/approved-email-domains",
      method: "GET",
    }),
    addApprovedEmailDomain: (pattern: string) => ({
      url: "/api/configuration/approved-email-domains",
      method: "POST",
      data: { pattern },
    }),
    removeApprovedEmailDomain: (pattern: string) => ({
      url: "/api/configuration/approved-email-domains/remove",
      method: "POST",
      data: { pattern },
    }),
    updateOrganisationConfiguration: (
      organisationId: string,
      configuration: Partial<OrganisationConfiguration>,
    ) => ({
      url: `/api/configuration/organisations/${organisationId}`,
      method: "POST",
      data: configuration,
    }),
  },
  work: {
    create: (itemId: string, createRequest: CreateWorkItemRequest) => ({
      url: `/api/work/${itemId}`,
      method: "put",
      data: createRequest,
      validateStatus: (status: number) =>
        (status >= 200 && status < 300) || [401, 400].includes(status),
    }),
    retrieveWork: (
      mode: "caseload" | "archive" | "user-caseload",
      params: {
        limit?: number;
        offset?: number;
        patientSearchFilter?: string;
        selectedFormTemplateIds?: string[];
        selectedSenderNames?: string[];
        fromDate?: string;
        toDate?: string;
      },
    ) => ({
      url: `/api/work`,
      method: "get",
      params: { mode, ...params },
    }),
    accept: (itemId: string, alsoClaim: boolean = true) => ({
      url: `/api/work/${itemId}/accept`,
      method: "post",
      params: { alsoClaim },
    }),
    reject: (
      itemId: string,
      rejectReason: string,
      rejectReasonDescription?: string,
    ) => ({
      url: `/api/work/${itemId}/reject`,
      method: "post",
      params: { rejectReason, rejectReasonDescription },
    }),

    claim: (itemId: string) => ({
      url: `/api/work/${itemId}/claim`,
      method: "post",
    }),
    unClaim: (itemId: string) => ({
      url: `/api/work/${itemId}/un-claim`,
      method: "post",
    }),
    archive: (itemId: string) => ({
      url: `/api/work/${itemId}/archive`,
      method: "post",
    }),
    unArchive: (itemId: string, alsoClaim: boolean = true) => ({
      url: `/api/work/${itemId}/un-archive`,
      method: "post",
      params: { alsoClaim },
    }),
    finalise: (itemId: string) => ({
      url: `/api/work/${itemId}/finalise`,
      method: "post",
    }),
    cancel: (itemId: string) => ({
      url: `/api/work/${itemId}/cancel`,
      method: "post",
    }),
  },
  audit: {
    searchByFormContextId: (
      formContextId: string,
      params: {
        limit?: number;
        offset?: number;
        fromDate: string;
        toDate: string;
      },
    ) => ({
      url: `/api/audit/form-context/${formContextId}`,
      method: "get",
      params: params,
    }),

    searchByPatientId: (
      patientId: string,
      params: {
        limit?: number;
        offset?: number;
        fromDate: string;
        toDate: string;
      },
    ) => ({
      url: `/api/audit/patient/${patientId}`,
      method: "get",
      params: params,
    }),
    searchByResourceId: (
      resourceId: string,
      params: {
        limit?: number;
        offset?: number;
        fromDate: string;
        toDate: string;
      },
    ) => ({
      url: `/api/audit/resource/${resourceId}`,
      method: "get",
      params: params,
    }),
    search: (params: {
      limit?: number;
      offset?: number;
      fromDate: string;
      toDate: string;
      resourceId: string | null;
      type: AuditTrailEntryType[] | null;
      userId: string | null;
    }) => ({
      url: `/api/audit`,
      method: "get",
      params: {
        ...params,
        type: (params.type || [])?.length > 0 ? params.type?.join(",") : null,
      },
    }),
  },
  formTemplates: {
    searchAssignmentOptions: (search: FormPartAssignmentOptionsSearch) => ({
      url: "/api/form-templates/assignment-options",
      method: "post",
      data: search,
    }),
  },
  users: {
    search: (
      query: string,
      limit: number,
      offset: number,
      managingOrganisationId?: UUID,
    ) => ({
      url: "/api/user/search",
      method: "get",
      params: { query, limit, offset, managingOrganisationId },
    }),
    get: (userId: string) => ({ url: `/api/user/${userId}`, method: "get" }),
    me: (contextId: string | null) => ({
      url: "/api/user/me",
      method: "get",
      params: contextId ? { "context-id": contextId } : {},
    }),
    loggedInCheck: () => ({ url: "/api/user/logged-in-check", method: "get" }),
    update: (userId: string, query: UserUpdate) => ({
      url: `/api/user/${userId}/update`,
      method: "post",
      data: query,
    }),
    updateEmail: (userId: string, email: string) => ({
      url: `/api/user/${userId}/update-email`,
      method: "post",
      data: { newEmail: email.trim().toLowerCase() },
    }),
    convertGuestUser: (
      userId: string,
      request: GuestUserConversionRequest,
    ) => ({
      url: `/api/user/${userId}/convert-guest-user`,
      method: "post",
      data: request,
    }),
    convertFullUserToGuest: (userId: string) => ({
      url: `/api/user/${userId}/convert-full-user-to-guest`,
      method: "post",
    }),
    create: (userId: string, body: UserCreate) => ({
      url: `/api/user/${userId}`,
      method: "put",
      data: body,
    }),
    delete: (userId: string) => ({
      url: `/api/user/${userId}/delete`,
      method: "put",
    }),
    createIndependentContext: (userId: string) => ({
      url: `/api/user/${userId}/independent-context`,
      method: "post",
    }),
    deleteIndependentContext: (userId: string) => ({
      url: `/api/user/${userId}/independent-context/delete`,
      method: "post",
    }),
    setSessionContext: (membership: Membership) => ({
      url: "/api/user/context",
      method: "post",
      data: membership,
    }),
    tryGuestUserLoginCode: (userId: string, code: string) => ({
      url: "/api/guests/login",
      method: "post",
      data: { userId, code },
      validateStatus: (status: number) => true,
    }),
    requestGuestUserLoginCode: (userId: string, forceSend?: boolean) => ({
      url: "/api/guests/request-code",
      method: "post",
      data: { userId },
      params: { forceSend },
      validateStatus: (status: number) => true,
    }),
    resetGuestUserLogins: (userId: string) => ({
      url: "/api/guests/admin/reset-logins",
      method: "post",
      data: { userId },
      validateStatus: (status: number) => true,
    }),
    triggerPasswordResetEmail: (userId: string) => ({
      url: `/api/user/${userId}/send-password-reset`,
      method: "post",
    }),
    bulkConfigure: (csvData: string, dryRun: boolean) => ({
      url: `/api/user/bulk-configure-users`,
      method: "post",
      data: { csv: csvData },
      params: { dryRun },
    }),
    bulkCreate: (
      csvData: string,
      dryRun: boolean,
      managingOrganisationId?: UUID,
    ) => {
      return {
        url: `/api/user/bulk-create`,
        method: "post",
        data: { csv: csvData },
        params: { dryRun, managingOrganisationId },
      };
    },
    enforceMfa: (userId: string) => ({
      url: `/api/user/${userId}/enforce-mfa`,
      method: "post",
    }),
    disableMfa: (userId: string) => ({
      url: `/api/user/${userId}/disable-mfa`,
      method: "post",
    }),
    resetMfa: (userId: string) => ({
      url: `/api/user/${userId}/reset-mfa`,
      method: "post",
    }),
  },
  teams: {
    create: (teamId: string, createTeamRequest: CreateTeamRequest) => ({
      url: `/api/team/${teamId}`,
      method: "put",
      data: createTeamRequest,
    }),
    update: (teamId: string, createTeamRequest: UpdateTeamRequest) => ({
      url: `/api/team/${teamId}`,
      method: "post",
      data: createTeamRequest,
    }),
    delete: (teamId: string, createTeamRequest: DeleteTeamRequest) => ({
      url: `/api/team/${teamId}/delete`,
      method: "put",
      data: createTeamRequest,
    }),
    get: (teamId: string) => ({ url: `/api/team/${teamId}`, method: "get" }),
    search: (
      query: string,
      limit: number = 10,
      offset: number = 0,
      ancestorOrganisationId?: string,
    ) => {
      return {
        url: `/api/team/search`,
        method: "get",
        params: {
          query,
          limit,
          offset,
          ancestorOrganisationId,
        },
      };
    },
    addMember: (teamId: string, req: AddTeamMemberRequest) => ({
      url: `/api/team/${teamId}/members/add`,
      method: "post",
      data: req,
    }),
    removeMember: (teamId: string, req: RemoveTeamMemberRequest) => ({
      url: `/api/team/${teamId}/members/remove`,
      method: "post",
      data: req,
    }),
  },
  organisations: {
    addUserToOrganisation: (organisationId: string, userId: string) => ({
      url: `/api/organisation/${organisationId}/add-user/${userId}`,
      method: "post",
    }),
    downloadAllUserEmails: (organisationId: string) => ({
      url: `/api/organisation/${organisationId}/download-all-user-emails`,
      method: "get",
    }),
    bulkUploadUsersToOrganisation: (
      organisationId: string,
      emails: string[],
      dryRun?: boolean,
    ) => ({
      url: `/api/organisation/${organisationId}/bulk-upload-users`,
      method: "post",
      data: emails,
      params: { dryRun },
    }),
    removeUserFromOrganisation: (organisationId: string, userId: string) => ({
      url: `/api/organisation/${organisationId}/remove-user/${userId}`,
      method: "post",
    }),
    addMember: (orgId: string, req: AddOrganisationMemberRequest) => ({
      url: `/api/organisation/${orgId}/members/add`,
      method: "post",
      data: req,
    }),
    removeMember: (orgId: string, req: RemoveOrganisationMemberRequest) => ({
      url: `/api/organisation/${orgId}/members/remove`,
      method: "post",
      data: req,
    }),
    create: (
      organisationId: string,
      createOrgReq: CreateOrganisationRequest,
    ) => ({
      url: `/api/organisation/${organisationId}`,
      method: "put",
      data: createOrgReq,
    }),
    update: (
      organisationId: string,
      updateOrgReq: UpdateOrganisationRequest,
    ) => ({
      url: `/api/organisation/${organisationId}`,
      method: "post",
      data: updateOrgReq,
    }),
    delete: (
      organisationId: string,
      updateOrgReq: DeleteOrganisationRequest,
    ) => ({
      url: `/api/organisation/${organisationId}/delete`,
      method: "put",
      data: updateOrgReq,
    }),
    get: (organisationId: string) => ({
      url: `/api/organisation/${organisationId}`,
      method: "get",
    }),
    search: (query: string, limit: number = 10, offset: number = 0) => {
      return {
        url: `/api/organisation/search`,
        method: "get",
        params: {
          query,
          limit,
          offset,
        },
      };
    },
  },
  dataSharingPartnership: {
    getAll: () => ({
      url: "/api/data-sharing-partnership",
      method: "GET",
    }),

    create: (
      id: DataSharingPartnership["id"],
      name: DataSharingPartnership["name"],
    ) => ({
      url: `/api/data-sharing-partnership/${id}`,
      method: "put",
      data: { name },
    }),

    delete: (id: DataSharingPartnership["id"]) => ({
      url: `/api/data-sharing-partnership/${id}/delete`,
      method: "post",
    }),

    addDataController: (
      dataPartnershipId: DataSharingPartnership["id"],
      dataControllerId: Organisation["id"],
    ) => ({
      url: `/api/data-sharing-partnership/${dataPartnershipId}/add-data-controller/${dataControllerId}`,
      method: "put",
    }),

    removeDataController: (
      dataPartnershipId: DataSharingPartnership["id"],
      dataControllerId: Organisation["id"],
    ) => ({
      url: `/api/data-sharing-partnership/${dataPartnershipId}/remove-data-controller/${dataControllerId}`,
      method: "post",
    }),
  },
  notifications: {
    get: (
      query: {
        limit?: number;
        offset?: number;
      } = {},
    ) => ({ url: "/api/notifications", method: "get", query }),
    markAsViewed: (notificationIds: string[]) => ({
      url: `/api/notifications/viewed`,
      method: "post",
      data: { notificationIds },
    }),
  },
  patients: {
    search: (query: PatientIndexQuery) => ({
      url: "/api/patients/search",
      method: "post",
      data: query,
    }),

    addSearchReasoning: (query: SearchReasoning) => ({
      url: "/api/patients/add-patient-search-reason",
      method: "post",
      data: query,
    }),
    merge: (query: PatientMergeInfo) => ({
      url: "/api/patients/merge",
      method: "post",
      data: query,
    }),
    unmerge: (
      query: PatientUnmergeIds & {
        formContextIds?: string[] | undefined;
        reason: string;
        reasonDescription?: string;
      },
    ) => ({
      url: "/api/patients/unmerge",
      method: "post",
      data: query,
    }),
    get: (patientId: string) => ({
      url: `/api/patients/${patientId}`,
      method: "get",
    }),
    getExternalPatientLinks: (
      patientId: string,
      query: { patientIdsToTreatAsRootPatients: string[] },
    ) => ({
      url: `/api/patients/${patientId}/external-patient-links`,
      params: query,
      paramsSerializer: {
        indexes: null, // Send repeated params without the [] suffix
      },
      method: "get",
    }),
    getTimeline: (
      patientId: string,
      query: {
        limit?: number;
        offset?: number;
      },
    ) => ({
      url: `/api/patients/${patientId}/timeline`,
      method: "get",
      params: query,
    }),
    getMhaState: (
      patientId: string,
      query: {
        limit: number;
        offset: number;
      },
    ) => ({
      url: `/api/patients/${patientId}/mha-state`,
      method: "get",
      params: query,
    }),
    getMhaDashboardData: (params: MhaDashboardDataParams) => ({
      url: "/api/patients/mha-dashboard-data",
      method: "get",
      params,
      paramsSerializer: {
        indexes: null, // Send repeated params without the [] suffix
      },
    }),
    getForms: (
      patientId: string,
      query: {
        formTemplateIds?: UUID[];
        statuses?: FormStatus[];
        completedAfter?: string;
        completedBefore?: string;
      },
    ) => ({
      url: `/api/patients/${patientId}/forms`,
      method: "get",
      params: {
        formTemplateIds: query.formTemplateIds?.join(","),
        statuses: query.statuses?.join(","),
        completedAfter: query.completedAfter,
        completedBefore: query.completedBefore,
      },
    }),
    create: (patientId: string, createPatient: CreatePatientRequest) => ({
      url: `/api/patients/${patientId}`,
      method: "put",
      data: createPatient,
    }),
    createFromExternalId: (request: CreatePatientFromExternalIdRequest) => ({
      url: `/api/patients/createFromExternalId`,
      method: "post",
      data: request,

      // Pass through all errors to the calling code to handle as we want
      // to diplay them (e.g. 502) to the user rather than throwing an error
      validateStatus: () => true,
    }),
    createExternalPatientDemographicsPullEvent: (
      patientId: string,
      request: CreateExternalPatientDemograhicsPullEventRequest,
    ) => ({
      url: `/api/patients/${patientId}/external-patient-link/pull-demographics`,
      method: "post",
      data: request,
    }),
    updateExternalPatientDemographicsPullEvent: (
      patientId: string,
      pullEventId: string,
      request: UpdateExternalPatientDemograhicsPullEventRequest,
    ) => ({
      url: `/api/patients/${patientId}/external-patient-link/pull-demographics/${pullEventId}`,
      method: "patch",
      data: request,
    }),
    getExternalPatientDemographicsPullEvent: (
      patientId: string,
      pullEventId: string,
    ) => ({
      url: `/api/patients/${patientId}/external-patient-link/pull-demographics/${pullEventId}`,
      method: "get",
    }),
    update: (patientId: string, updatePatient: UpdatePatientRequest) => ({
      url: `/api/patients/${patientId}`,
      method: "patch",
      data: updatePatient,
    }),
    updatePatientMhaStateWithManualEvent: (
      patientId: string,
      request: UpdatePatientStateWithManualEventRequest,
    ) => ({
      url: `/api/patients/${patientId}/mha-state`,
      method: "post",
      data: request,
    }),
    getPatientMhaState: (patientId: string) => ({
      url: `/api/patients/${patientId}/mha-state`,
      method: "get",
    }),
  },
  forms: {
    createAdmissionsContext: (formContextId: string, patientId: string) => ({
      url: `/api/form-context/${formContextId}`,
      method: "put",
      data: { type: "admission", patientId },
    }),
    uploadPdfToExternalSystem: (
      formContextId: string,
      formId: string,
      externalPatientLinkId: string,
      filename: string,
      title: string,
      description: string,
    ) => ({
      url: `/api/form-context/${formContextId}/form/${formId}/uploadPdfToExternalSystem`,
      method: "post",
      data: { externalPatientLinkId, filename, title, description },
    }),
    getExternalPatientFormPushEvent: (
      formContextId: string,
      formId: string,
    ) => ({
      url: `/api/form-context/${formContextId}/form/${formId}/externalPatientFormPushEvent`,
      method: "get",
    }),
    uploadForm: (
      formContextId: string,
      formId: string,
      data: UploadFormRequest,
    ) => ({
      url: `/api/form-context/${formContextId}/form/${formId}/upload`,
      method: "put",
      data: data,
    }),
    uploadFormPreview: (
      formContextId: string,
      formId: string,
      data: UploadFormRequest,
    ) => ({
      url: `/api/form-context/${formContextId}/form/${formId}/upload-preview`,
      method: "post",
      data: data,
    }),
    sign: (formId: string, createFormFromDraft: SignDraftRequest) => ({
      url: `/api/forms/${formId}/sign`,
      method: "put",
      data: createFormFromDraft,
    }),
    requestAmend: (formId: string, amendRequest: RequestAmendRequest) => ({
      url: `/api/forms/${formId}/request-amend`,
      method: "post",
      data: amendRequest,
    }),
    addFileNote: (formId: string, fileNote: AddFileNote) => ({
      url: `/api/forms/${formId}/add-file-note`,
      method: "post",
      data: fileNote,
    }),
    deleteFileNotes: (
      formId: string,
      fileNoteIds: { fileNoteIds: string },
    ) => ({
      url: `/api/forms/${formId}/delete-file-notes`,
      method: "post",
      data: fileNoteIds,
    }),
    getFileNotes: (formId: string) => ({
      url: `/api/forms/${formId}/get-file-notes`,
      method: "get",
    }),
    delete: (formId: string) => ({
      url: `/api/forms/${formId}`,
      method: "delete",
    }),
    createFromAssignment: (
      formId: string,
      createFormFromDraft: CreateFormRequest,
    ) => ({
      url: `/api/forms/${formId}`,
      method: "put",
      data: createFormFromDraft,
    }),
    get: (formId?: string) => ({
      url: `/api/forms/${formId}`,
      method: "get",
    }),
    getFormContext: (formContextId?: string) => ({
      url: `/api/form-context/${formContextId}`,
      method: "get",
    }),
    getPdf: (
      formId?: string,
      includeLinkedForms?: boolean,
      version?: number,
    ) => ({
      url: `/api/forms/${formId}/pdf`,
      method: "get",
      params: { includeLinkedForms, version },
    }),
    getRioUploadData: (formContextId: string, formId: string) => ({
      url: `/api/form-context/${formContextId}/form/${formId}/get-rio-upload-data`,
      method: "get",
    }),
  },
  drafts: {
    create: (formDraftId: string, createDraft: CreateDraft) => ({
      url: `/api/formdrafts/${formDraftId}`,
      method: "put",
      data: createDraft,
    }),
    getAll: (patientId?: string) => ({
      url: "/api/formdrafts",
      method: "get",
      params: { patientId },
    }),
    get: (formDraftId: string) => ({
      url: `/api/formdrafts/${formDraftId}`,
      method: "get",
    }),
    getPdf: (formDraftId: string, review: boolean) => ({
      url: `/api/formdrafts/${formDraftId}/pdf`,
      method: "get",
      params: review ? { review: "true" } : {},
    }),
    delete: (formDraftId: string) => ({
      url: `/api/formdrafts/${formDraftId}`,
      method: "delete",
    }),
    updateData: (formDraftId: string, data: any) => ({
      url: `/api/formdrafts/${formDraftId}/data`,
      method: "post",
      data: data,
    }),
  },
  admin: {
    getGuestUserLoginCode: (userId: string) => ({
      url: `/api/guests/admin/request-code`,
      method: "post",
      data: { userId },
    }),
  },
  rio: {
    instance: {
      get: (rioInstanceId: string) => ({
        url: `/api/epr/rio/instances/${rioInstanceId}`,
        method: "get",
      }),
      create: (rioInstanceId: string, data: UpsertRioInstanceRequest) => ({
        url: `/api/epr/rio/instances/${rioInstanceId}`,
        method: "put",
        data: data,
      }),
      update: (rioInstanceId: string, data: UpsertRioInstanceRequest) => ({
        url: `/api/epr/rio/instances/${rioInstanceId}`,
        method: "post",
        data: data,
      }),
      search: (query: string, limit: number = 10, offset: number = 0) => ({
        url: `/api/epr/rio/search/instances`,
        method: "get",
        params: {
          query,
          limit,
          offset,
        },
      }),
      delete: (rioInstanceId: string) => ({
        url: `/api/epr/rio/instances/${rioInstanceId}`,
        method: "delete",
      }),
    },
    clickThrough: {
      createAndClaimToken: (
        data: CreateAndClaimOneTimeTokenForRioClickThroughInstanceRequest,
      ) => ({
        url: `/api/epr/rio/createAndClaimToken`,
        method: "post",
        data: data,
      }),
      redeemToken: (
        data: RedeemOneTimeTokenForRioClickThroughInstanceRequest,
      ) => ({
        url: `/api/epr/rio/redeemToken`,
        method: "post",
        data: data,
      }),
    },
  },
  templates: {
    compileTemplate: (template: string, templateData: TemplateData) => ({
      url: "/api/templates/compile",
      method: "post",
      data: { template, templateData },
    }),
  },
};

export const api = {
  patientBulkImport: {
    create: (id: UUID, body: CreatePatientBulkImportTokenRequest) =>
      apiAxios.request<void>(apiConfig.patientBulkImport.create(id, body)),
    revoke: (id: UUID) =>
      apiAxios.request<void>(apiConfig.patientBulkImport.revoke(id)),
    use: (id: UUID, csv: string, dryRun: boolean) =>
      apiAxios.request<UsePatientBulkImportTokenResponse>(
        apiConfig.patientBulkImport.use(id, csv, dryRun),
      ),
  },
  system: {
    generatePresignedUploadUrl: (body: GeneratePresignedUrlRequestBody) =>
      apiAxios.request<GeneratePresignedUrlResponseBody>(
        apiConfig.system.generatePresignedUploadUrl(body),
      ),
  },

  termsAndConditions: {
    getAll: () =>
      apiAxios.request<TermsAndConditionsResponse>(
        apiConfig.termsAndConditions.getAll(),
      ),
    get: (id: UUID) =>
      apiAxios.request<TermsAndConditions>(
        apiConfig.termsAndConditions.get(id),
      ),
    create: (id: UUID, req: CreateTermsAndConditionsRequest) =>
      apiAxios.request<void>(apiConfig.termsAndConditions.create(id, req)),
    accept: (req: AcceptTermsAndConditionsRequest) =>
      apiAxios.request<void>(apiConfig.termsAndConditions.accept(req)),
  },

  admissions: {
    recordReasonForNotProceedingMedRec: (
      workItemId: string,
      reason: string,
      reasonDescription?: string,
    ) =>
      apiAxios.request(
        apiConfig.admissions.recordReasonForNotProceedingMedRec(
          workItemId,
          reason,
          reasonDescription,
        ),
      ),
    recordReasonForNotProceedingAppForm: (
      workItemId: string,
      reason: string,
      reasonDescription?: string,
    ) =>
      apiAxios.request(
        apiConfig.admissions.recordReasonForNotProceedingAppForm(
          workItemId,
          reason,
          reasonDescription,
        ),
      ),

    reOpenAdmission: (
      workItemId: string,
      reason: string,
      reasonDescription?: string,
    ) =>
      apiAxios.request(
        apiConfig.admissions.reOpenAdmission(
          workItemId,
          reason,
          reasonDescription,
        ),
      ),
  },
  admin: {
    getGuestUserLoginCode: (userId: string) =>
      apiAxios.request<{
        code: string;
        url: string;
        created: string;
      }>(apiConfig.admin.getGuestUserLoginCode(userId)),
  },
  upload: {
    uploadForm: (
      formContextId: string,
      formId: string,
      data: UploadFormRequest,
    ) =>
      apiAxios.request<any>(
        apiConfig.forms.uploadForm(formContextId, formId, data),
      ),
  },
  reports: {
    // Directly returns a CSV string
    // TODO: send a signed s3 url to download instead?
    generateSignedFormsCsv: (params: SignedFormsCsvParams) =>
      apiAxios.request<string>(
        apiConfig.reports.generateSignedFormsCsv(params),
      ),
  },
  configuration: {
    addAdminManagedLocation: (
      location: Omit<ManagedLocation, "id" | "status">,
    ) => apiAxios.request<void>(apiConfig.configuration.addLocation(location)),
    updateAdminManagedLocation: (
      id: string,
      location: Omit<ManagedLocation, "id" | "status">,
    ) =>
      apiAxios.request<void>(
        apiConfig.configuration.updateLocation(id, location),
      ),
    deleteAdminManagedLocation: (id: ManagedLocation["id"]) =>
      apiAxios.request<void>(apiConfig.configuration.deleteLocation(id)),
    addApprovedEmailDomain: (domain: string) =>
      apiAxios.request(apiConfig.configuration.addApprovedEmailDomain(domain)),
    removeApprovedEmailDomain: (domain: string) =>
      apiAxios.request(
        apiConfig.configuration.removeApprovedEmailDomain(domain),
      ),
    updateOrganisationConfiguration: (
      organisationId: string,
      configuration: Partial<OrganisationConfiguration>,
    ) =>
      apiAxios.request(
        apiConfig.configuration.updateOrganisationConfiguration(
          organisationId,
          configuration,
        ),
      ),
  },
  work: {
    create: (workItemId: string, createRequest: CreateWorkItemRequest) =>
      apiAxios.request(apiConfig.work.create(workItemId, createRequest)),
    accept: (workItemId: string) =>
      apiAxios.request(apiConfig.work.accept(workItemId)),
    claim: (workItemId: string) =>
      apiAxios.request(apiConfig.work.claim(workItemId)),
    unClaim: (workItemId: string) =>
      apiAxios.request(apiConfig.work.unClaim(workItemId)),
    reject: (
      workItemId: string,
      rejectionReason: string,
      rejectReasonDescription?: string,
    ) =>
      apiAxios.request(
        apiConfig.work.reject(
          workItemId,
          rejectionReason,
          rejectReasonDescription,
        ),
      ),

    archive: (workItemId: string) =>
      apiAxios.request(apiConfig.work.archive(workItemId)),
    unArchive: (workItemId: string) =>
      apiAxios.request(apiConfig.work.unArchive(workItemId)),
    finalise: (workItemId: string) =>
      apiAxios.request(apiConfig.work.finalise(workItemId)),
    cancel: (workItemId: string) =>
      apiAxios.request(apiConfig.work.cancel(workItemId)),
  },
  formTemplates: {
    searchAssignmentOptions: (search: FormPartAssignmentOptionsSearch) =>
      apiAxios.request<{ results: UserOrTeamSearchResult[] }>(
        apiConfig.formTemplates.searchAssignmentOptions(search),
      ),
  },
  users: {
    search: (
      query: string,
      limit: number,
      offset: number,
      managingOrganisationId?: UUID,
    ) =>
      apiAxios.request<{
        results: Omit<ExtendedThalamosUser, "defaultSignature">[];
        count: number;
      }>(apiConfig.users.search(query, limit, offset, managingOrganisationId)),
    me: (contextId: string | null) =>
      apiAxios.request<ExtendedThalamosUser | null>(
        apiConfig.users.me(contextId),
      ),
    loggedInCheck: () =>
      apiAxios.request<null>(apiConfig.users.loggedInCheck()),
    get: (userId: string) =>
      apiAxios.request<ExtendedThalamosUser | null>(
        apiConfig.users.get(userId),
      ),
    update: (userId: string, query: UserUpdate) =>
      apiAxios.request<ExtendedThalamosUser | null>(
        apiConfig.users.update(userId, query),
      ),
    updateEmail: (userId: string, newEmail: string) =>
      apiAxios.request<ExtendedThalamosUser | null>(
        apiConfig.users.updateEmail(userId, newEmail),
      ),
    convertGuestUser: (userId: string, query: GuestUserConversionRequest) =>
      apiAxios.request<void>(apiConfig.users.convertGuestUser(userId, query)),
    convertFullUserToGuest: (userId: string) =>
      apiAxios.request<any>(apiConfig.users.convertFullUserToGuest(userId)),
    create: (userId: string, body: UserCreate) =>
      apiAxios.request<void>(apiConfig.users.create(userId, body)),
    bulkConfigure: (csvData: string, dryRun: boolean) =>
      apiAxios.request<void>(apiConfig.users.bulkConfigure(csvData, dryRun)),
    bulkCreate: (
      csvData: string,
      dryRun: boolean,
      managingOrganisationId?: UUID,
    ) =>
      apiAxios.request<void>(
        apiConfig.users.bulkCreate(csvData, dryRun, managingOrganisationId),
      ),
    delete: (userId: string) =>
      apiAxios.request<void>(apiConfig.users.delete(userId)),
    createIndependentContext: (userId: string) =>
      apiAxios.request<any>(apiConfig.users.createIndependentContext(userId)),
    deleteIndependentContext: (userId: string) =>
      apiAxios.request<any>(apiConfig.users.deleteIndependentContext(userId)),
    setSessionContext: (membership: Membership) =>
      apiAxios.request<void>(apiConfig.users.setSessionContext(membership)),
    requestGuestUserLoginCode: (userId: string, forceSend?: boolean) =>
      apiAxios.request<{ email: string; sent: boolean }>(
        apiConfig.users.requestGuestUserLoginCode(userId, forceSend),
      ),
    resetGuestUserLogins: (userId: string) =>
      apiAxios.request<{ email: string; sent: boolean }>(
        apiConfig.users.resetGuestUserLogins(userId),
      ),
    tryGuestUserLoginCode: (userId: string, code: string) =>
      apiAxios.request<void>(
        apiConfig.users.tryGuestUserLoginCode(userId, code),
      ),
    triggerPasswordResetEmail: (userId: string) =>
      apiAxios.request<void>(apiConfig.users.triggerPasswordResetEmail(userId)),
    enforceMfa: (userId: string) =>
      apiAxios.request<void>(apiConfig.users.enforceMfa(userId)),
    resetMfa: (userId: string) =>
      apiAxios.request<void>(apiConfig.users.resetMfa(userId)),
    disableMfa: (userId: string) =>
      apiAxios.request<void>(apiConfig.users.disableMfa(userId)),
  },
  notifications: {
    markAsViewed: (notificationIds: string[]) =>
      apiAxios.request(apiConfig.notifications.markAsViewed(notificationIds)),
  },
  patients: {
    get: (patientId: string) =>
      apiAxios.request<ExtendedPatient | null>(
        apiConfig.patients.get(patientId),
      ),
    search: (query: PatientIndexQuery) =>
      apiAxios.request<PatientIndexSearchResult>(
        apiConfig.patients.search(query),
      ),

    addSearchReasoning: (query: SearchReasoning) =>
      apiAxios.request<PatientIndexSearchResult>(
        apiConfig.patients.addSearchReasoning(query),
      ),
    create: (patientId: string, createPatient: CreatePatientRequest) =>
      apiAxios.request<Patient>(
        apiConfig.patients.create(patientId, createPatient),
      ),
    createFromExternalId: (request: CreatePatientFromExternalIdRequest) =>
      apiAxios.request<CreatePatientFromExternalIdResponse>(
        apiConfig.patients.createFromExternalId(request),
      ),
    getExternalPatientLinks: (
      patientId: string,
      patientIdsToTreatAsRootPatients?: string[],
    ) =>
      apiAxios.request<GetExternalPatientLinksResponse>(
        apiConfig.patients.getExternalPatientLinks(patientId, {
          patientIdsToTreatAsRootPatients:
            patientIdsToTreatAsRootPatients ?? [],
        }),
      ),
    createExternalPatientDemographicsPullEvent: (
      patientId: string,
      request: CreateExternalPatientDemograhicsPullEventRequest,
    ) =>
      apiAxios.request<CreateExternalPatientDemograhicsPullEventResponse>(
        apiConfig.patients.createExternalPatientDemographicsPullEvent(
          patientId,
          request,
        ),
      ),
    updateExternalPatientDemographicsPullEvent: (
      patientId: string,
      pullEventId: string,
      request: UpdateExternalPatientDemograhicsPullEventRequest,
    ) =>
      apiAxios.request<UpdateExternalPatientDemograhicsPullEventResponse>(
        apiConfig.patients.updateExternalPatientDemographicsPullEvent(
          patientId,
          pullEventId,
          request,
        ),
      ),
    getExternalPatientDemographicsPullEvent: (
      patientId: string,
      pullEventId: string,
    ) =>
      apiAxios.request<GetExternalPatientDemograhicsPullEventResponse>(
        apiConfig.patients.getExternalPatientDemographicsPullEvent(
          patientId,
          pullEventId,
        ),
      ),
    updatePatientMhaStateWithManualEvent: (
      patientId: string,
      request: UpdatePatientStateWithManualEventRequest,
    ) =>
      apiAxios.request<null>(
        apiConfig.patients.updatePatientMhaStateWithManualEvent(
          patientId,
          request,
        ),
      ),
    getPatientMhaState: (patientId: string) =>
      apiAxios.request<GetPatientMhaStateResponse>(
        apiConfig.patients.getPatientMhaState(patientId),
      ),
    update: (patientId: string, updatePatient: UpdatePatientRequest) =>
      apiAxios.request<Patient | null>(
        apiConfig.patients.update(patientId, updatePatient),
      ),
    merge: (query: PatientMergeInfo) =>
      apiAxios.request<PatientIndexSearchResult>(
        apiConfig.patients.merge(query),
      ),

    unmerge: (
      query: PatientUnmergeIds & {
        formContextIds?: string[] | undefined;
        reason: string;
        reasonDescription?: string;
      },
    ) => apiAxios.request<any>(apiConfig.patients.unmerge(query)),

    getMhaDashboardData: (params: MhaDashboardDataParams) =>
      apiAxios.request<MhaDashboardDataResponse>(
        apiConfig.patients.getMhaDashboardData(params),
      ),
  },
  forms: {
    getPdf: (formId: string, includeLinkedForms?: boolean, version?: number) =>
      apiAxios.request<string | null>(
        apiConfig.forms.getPdf(formId, includeLinkedForms, version),
      ),
    createAdmissionsContext: (formContextId: string, patientId: string) =>
      apiAxios.request<null>(
        apiConfig.forms.createAdmissionsContext(formContextId, patientId),
      ),
    uploadPdfToExternalSystem: (
      formContextId: string,
      formId: string,
      externalPatientLinkId: string,
      filename: string,
      title: string,
      description: string,
    ) =>
      apiAxios.request<null>(
        apiConfig.forms.uploadPdfToExternalSystem(
          formContextId,
          formId,
          externalPatientLinkId,
          filename,
          title,
          description,
        ),
      ),
    getExternalPatientFormPushEvent: (formContextId: string, formId: string) =>
      apiAxios.request<GetExternalPatientFormPushEventResponse | null>(
        apiConfig.forms.getExternalPatientFormPushEvent(formContextId, formId),
      ),
    delete: (formId: string) =>
      apiAxios.request<null>(apiConfig.forms.delete(formId)),
    sign: (formId: string, createFormFromDraft: SignDraftRequest) =>
      apiAxios.request<null>(apiConfig.forms.sign(formId, createFormFromDraft)),
    requestAmend: (formId: string, amendRequest: RequestAmendRequest) =>
      apiAxios.request<null>(
        apiConfig.forms.requestAmend(formId, amendRequest),
      ),
    addFileNote: (formId: string, fileNote: AddFileNote) =>
      apiAxios.request<null>(apiConfig.forms.addFileNote(formId, fileNote)),
    deleteFileNotes: (formId: string, fileNoteIds: { fileNoteIds: string }) =>
      apiAxios.request<null>(
        apiConfig.forms.deleteFileNotes(formId, fileNoteIds),
      ),
    create: (
      formId: string,
      createFormFromAssignmentRequest: CreateFormRequest,
    ) =>
      apiAxios.request<null>(
        apiConfig.forms.createFromAssignment(
          formId,
          createFormFromAssignmentRequest,
        ),
      ),
    getRioUploadData: (formContextId: string, formId: string) =>
      apiAxios.request<RioUploadData>(
        apiConfig.forms.getRioUploadData(formContextId, formId),
      ),
  },
  teams: {
    addMember: (teamId: string, req: AddTeamMemberRequest) =>
      apiAxios.request(apiConfig.teams.addMember(teamId, req)),
    removeMember: (teamId: string, req: RemoveTeamMemberRequest) =>
      apiAxios.request(apiConfig.teams.removeMember(teamId, req)),
    create: (teamId: string, req: CreateTeamRequest) =>
      apiAxios.request(apiConfig.teams.create(teamId, req)),
    update: (teamId: string, req: UpdateTeamRequest) =>
      apiAxios.request(apiConfig.teams.update(teamId, req)),
    delete: (teamId: string, req: DeleteTeamRequest) =>
      apiAxios.request(apiConfig.teams.delete(teamId, req)),
    search: (query: string, limit: number, offset: number) => {
      return apiAxios.request<
        any,
        AxiosResponse<{
          results: ExtendedTeam[];
          count: number;
        }>
      >(apiConfig.teams.search(query, limit, offset));
    },
  },
  organisations: {
    addUserToOrganisation: (orgId: string, userId: string) =>
      apiAxios.request(
        apiConfig.organisations.addUserToOrganisation(orgId, userId),
      ),

    downloadAllUserEmails: (orgId: string) =>
      apiAxios.request(apiConfig.organisations.downloadAllUserEmails(orgId)),

    bulkUploadUsersToOrganisation: (
      orgId: string,
      emails: string[],
      dryRun?: boolean,
    ) =>
      apiAxios.request(
        apiConfig.organisations.bulkUploadUsersToOrganisation(
          orgId,
          emails,
          dryRun,
        ),
      ),

    removeUserFromOrganisation: (orgId: string, userId: string) =>
      apiAxios.request(
        apiConfig.organisations.removeUserFromOrganisation(orgId, userId),
      ),
    addMember: (teamId: string, req: AddOrganisationMemberRequest) =>
      apiAxios.request(apiConfig.organisations.addMember(teamId, req)),
    removeMember: (teamId: string, req: RemoveOrganisationMemberRequest) =>
      apiAxios.request(apiConfig.organisations.removeMember(teamId, req)),
    create: (orgId: string, req: CreateOrganisationRequest) =>
      apiAxios.request(apiConfig.organisations.create(orgId, req)),
    update: (orgId: string, req: UpdateOrganisationRequest) =>
      apiAxios.request(apiConfig.organisations.update(orgId, req)),
    delete: (orgId: string, req: DeleteOrganisationRequest) =>
      apiAxios.request(apiConfig.organisations.delete(orgId, req)),
    search: (query: string, limit: number, offset: number) => {
      return apiAxios.request<
        any,
        AxiosResponse<{
          results: ExtendedOrganisation[];
          count: number;
        }>
      >(apiConfig.organisations.search(query, limit, offset));
    },
  },
  dataSharingPartnership: {
    create: (
      id: DataSharingPartnership["id"],
      name: DataSharingPartnership["name"],
    ) => {
      return apiAxios.request<void>(
        apiConfig.dataSharingPartnership.create(id, name),
      );
    },
    delete: (id: DataSharingPartnership["id"]) => {
      return apiAxios.request<void>(
        apiConfig.dataSharingPartnership.delete(id),
      );
    },
    addDataController: (
      id: DataSharingPartnership["id"],
      dataControllerId: string,
    ) => {
      return apiAxios.request<void>(
        apiConfig.dataSharingPartnership.addDataController(
          id,
          dataControllerId,
        ),
      );
    },
    removeDataController: (
      id: DataSharingPartnership["id"],
      dataControllerId: string,
    ) => {
      return apiAxios.request<void>(
        apiConfig.dataSharingPartnership.removeDataController(
          id,
          dataControllerId,
        ),
      );
    },
  },
  drafts: {
    create: (formDraftId: string, createDraft: CreateDraft) =>
      apiAxios.request<null>(apiConfig.drafts.create(formDraftId, createDraft)),
    get: (formDraftId: string) =>
      apiAxios.request<GetFormDraftResponse<any> | null>(
        apiConfig.drafts.get(formDraftId),
      ),
    getPdf: (formDraftId: string, review: boolean) =>
      apiAxios.request<string>(apiConfig.drafts.getPdf(formDraftId, review)),
    delete: (formDraftId: string) =>
      apiAxios.request<null>(apiConfig.drafts.delete(formDraftId)),
    updateData: (formDraftId: string, data: any) =>
      apiAxios.request<null>(apiConfig.drafts.updateData(formDraftId, data)),
  },
  rio: {
    instance: {
      create: (rioInstanceId: string, req: UpsertRioInstanceRequest) =>
        apiAxios.request(apiConfig.rio.instance.create(rioInstanceId, req)),
      update: (rioInstanceId: string, req: UpsertRioInstanceRequest) =>
        apiAxios.request(apiConfig.rio.instance.update(rioInstanceId, req)),
      delete: (rioInstanceId: string) =>
        apiAxios.request(apiConfig.rio.instance.delete(rioInstanceId)),
    },

    clickThrough: {
      createAndClaimToken: (
        req: CreateAndClaimOneTimeTokenForRioClickThroughInstanceRequest,
      ) =>
        apiAxios.request<CreateAndClaimOneTimeTokenForRioClickThroughInstanceResponse>(
          apiConfig.rio.clickThrough.createAndClaimToken(req),
        ),
      redeemToken: (req: RedeemOneTimeTokenForRioClickThroughInstanceRequest) =>
        apiAxios.request<RedeemOneTimeTokenForRioClickThroughInstanceResponse>(
          apiConfig.rio.clickThrough.redeemToken(req),
        ),
    },
  },
  templates: {
    compileTemplate: (template: string, templateData: TemplateData) =>
      apiAxios.request<string>(
        apiConfig.templates.compileTemplate(template, templateData),
      ),
  },
};

/*
  Creates a hook that automatically reloads useAxios data based on events received on a pusher channel
 */
function usePusherChannelReload<TResponse>(
  channelName: string,
  config: AxiosRequestConfig<TResponse> | string,
  options?: Options,
): UseAxiosResult<TResponse, any, any> {
  const channel = useChannel(channelName);
  const result = useApiAxios<TResponse>(config as any, options);
  const reloadFn = debounce(() => {
    return result[1]();
  }, 500);
  useEvent(channel, NotificationEvents.resourceReload, (event) => {
    reloadFn();
  });
  const debouncedReloadFn = reloadFn as RefetchFunction<any, TResponse>;
  return [result[0], debouncedReloadFn, result[2]];
}

export const apiHooks = {
  patientBulkImport: {
    get: function usePatientBulkImportGetAll(token: UUID) {
      return useApiAxios<ExtendedPatientBulkImportToken | null>(
        apiConfig.patientBulkImport.get(token),
      );
    },
    getAll: function usePatientBulkImportGetAll() {
      return useApiAxios<GetAllPatientBulkImportTokensResponse>(
        apiConfig.patientBulkImport.getAll(),
      );
    },
  },
  reports: {
    availableOrgsAndTeams: function useAvailableOrgsAndTeams(params: {
      teamId?: string;
      orgId?: string;
    }) {
      return useApiAxios<OrgsAndTeamsResult>(
        apiConfig.reports.availableOrgsAndTeams(params),
      );
    },
    dashboardData: function useDashboardData(params: ReportContextParams) {
      return useApiAxios<DashboardData>(
        apiConfig.reports.getDashboardData(params),
      );
    },
  },
  configuration: {
    getAdminManagedLocations: function useAdminManagedLocations() {
      return useApiAxios<ManagedLocation[]>(
        apiConfig.configuration.getLocations(),
      );
    },
    getApprovedEmailDomains: function useApprovedEmailDomains() {
      return useApiAxios<{ pattern: string }[]>(
        apiConfig.configuration.getApprovedEmailDomains(),
      );
    },
  },

  work: {
    retrieveWork: function useWorkItemGet(
      userId: string,
      contextId: string,
      mode: "caseload" | "archive" | "user-caseload",
      params: {
        limit?: number;
        offset?: number;
        patientSearchFilter?: string;
        selectedFormTemplateIds?: string[];
        selectedSenderNames?: string[];
        fromDate?: string;
        toDate?: string;
      },
    ) {
      return usePusherChannelReload<GetWorkResponse>(
        mode === "user-caseload"
          ? notificationChannelFns.work.userItems(userId)
          : mode === "archive"
            ? notificationChannelFns.work.archive(contextId)
            : notificationChannelFns.work.items(contextId),
        apiConfig.work.retrieveWork(mode, params),
      );
    },
  },
  audit: {
    searchByFormContextId: function useSearchByResourceId(
      formContextId: string,
      params: {
        limit?: number;
        offset?: number;
        fromDate: string;
        toDate: string;
      },
    ) {
      return useApiAxios<{ entries: AuditTrailEntry[]; count: number }>(
        apiConfig.audit.searchByFormContextId(formContextId, params),
      );
    },

    searchByPatientId: function useSearchByPatientId(
      patientId: string,
      params: {
        limit?: number;
        offset?: number;
        fromDate: string;
        toDate: string;
      },
    ) {
      return useApiAxios<{ entries: AuditTrailEntry[]; count: number }>(
        apiConfig.audit.searchByPatientId(patientId, params),
      );
    },
    search: function useSearchByResourceId(params: {
      limit?: number;
      offset?: number;
      fromDate: string;
      toDate: string;
      resourceId: string | null;
      type: AuditTrailEntryType[] | null;
      userId: UUID | null;
    }) {
      return useApiAxios<{ entries: AuditTrailEntry[]; count: number }>(
        apiConfig.audit.search(params),
      );
    },
    searchByResourceId: function useSearchByResourceId(
      resourceId: string,
      params: {
        limit?: number;
        offset?: number;
        fromDate: string;
        toDate: string;
      },
    ) {
      return useApiAxios<{ entries: AuditTrailEntry[]; count: number }>(
        apiConfig.audit.searchByResourceId(resourceId, params),
      );
    },
  },
  forms: {
    getFormContext: function useFormsGet(formContextId: string) {
      return usePusherChannelReload<FormContextData | null>(
        notificationChannelFns.formContext(formContextId),
        apiConfig.forms.getFormContext(formContextId),
      );
    },
    get: function useFormsGet(formId: string) {
      return usePusherChannelReload<GetFormResponse<any> | null>(
        notificationChannelFns.form(formId),
        apiConfig.forms.get(formId),
      );
    },
    getPdf: function useFormsGetPdf(
      formId: string,
      includeLinkedForms?: boolean,
    ) {
      return usePusherChannelReload<string | null>(
        notificationChannelFns.form(formId),
        apiConfig.forms.getPdf(formId, includeLinkedForms),
      );
    },

    getFileNotes: function useGetFileNotes(formId: string) {
      return useApiAxios<FileNote[]>(apiConfig.forms.getFileNotes(formId));
    },
    uploadFormPreview: function useUploadFormPreview(
      formContextId: string,
      formId: string,
      data: UploadFormRequest,
    ) {
      return useApiAxios<string>(
        apiConfig.forms.uploadFormPreview(formContextId, formId, data),
      );
    },
  },
  notifications: {
    get: function useNotificationsGet(
      teamId: string | undefined,
      query: {
        limit?: number;
        offset?: number;
      } = {},
    ) {
      return usePusherChannelReload<NotificationsResponse>(
        notificationChannelFns.team.notifications(teamId),
        apiConfig.notifications.get(query),
      );
    },
  },
  teams: {
    get: function useTeamGet(teamId: string) {
      return usePusherChannelReload<ExtendedTeam | null>(
        notificationChannelFns.team.profile(teamId),
        apiConfig.teams.get(teamId),
      );
    },
    search: function useTeamSearch(
      query: string,
      limit: number,
      offset: number,
      ancestorOrganisationId?: string,
    ) {
      return useApiAxios<{
        results: ExtendedTeam[];
        count: number;
      }>(apiConfig.teams.search(query, limit, offset, ancestorOrganisationId));
    },
  },
  organisations: {
    get: function useOrgGet(organisationId: string) {
      return useApiAxios<GetOrganisationResponse | null>(
        apiConfig.organisations.get(organisationId),
      );
    },
    search: function useOrgSearch(
      query: string,
      limit: number,
      offset: number,
    ) {
      return useApiAxios<{
        results: ExtendedOrganisation[];
        count: number;
      }>(apiConfig.organisations.search(query, limit, offset));
    },
  },
  dataSharingPartnership: {
    getAll: function useDspGetAll() {
      return useApiAxios<DataSharingPartnershipWithDataControllers[]>(
        apiConfig.dataSharingPartnership.getAll(),
      );
    },
  },
  users: {
    search: function useUserSearch(
      query: string,
      limit: number,
      offset: number,
      managingOrganisationId?: string,
    ) {
      return useApiAxios<{
        results: Omit<ExtendedThalamosUser, "defaultSignature">[];
        count: number;
      }>(apiConfig.users.search(query, limit, offset, managingOrganisationId));
    },
    me: function useUserMe(contextId: string | null) {
      const data = useApiAxios<ExtendedThalamosUser | null>(
        apiConfig.users.me(contextId),
      );

      if (data[0]?.response?.status === 200) {
        currentUserId = data[0].data!.id;
      }

      return data;
    },
    getUserViaPusher: function useGetUser(userId: string) {
      return usePusherChannelReload<ExtendedThalamosUser | null>(
        notificationChannelFns.user.profile(userId),
        apiConfig.users.get(userId),
      );
    },

    getUserDetails: function useGetUser(userId: string) {
      return useApiAxios<ExtendedThalamosUser | null>(
        apiConfig.users.get(userId),
      );
    },
  },
  patients: {
    get: function useGetPatient(patientId: string) {
      return usePusherChannelReload<GetPatientResponse | null>(
        notificationChannelFns.patient(patientId),
        apiConfig.patients.get(patientId),
      );
    },

    getExternalPatientLinks: function useGetExternalPatientLinks(
      patientId: string,
      query: {
        patientIdsToTreatAsRootPatients: string[];
      },
    ) {
      return usePusherChannelReload<GetExternalPatientLinksResponse>(
        notificationChannelFns.patientTimeline(patientId),
        apiConfig.patients.getExternalPatientLinks(patientId, query),
      );
    },

    getTimeline: function useGetPatientTimeline(
      patientId: string,
      query: {
        limit?: number;
        offset?: number;
      } = {},
    ) {
      return usePusherChannelReload<PatientTimelineResponse>(
        notificationChannelFns.patientTimeline(patientId),
        apiConfig.patients.getTimeline(patientId, query),
      );
    },

    getMhaState: function useGetMhaState(
      patientId: string,
      query: {
        limit: number;
        offset: number;
      },
    ) {
      return usePusherChannelReload<{ states: PatientStateWithContext[] }>(
        notificationChannelFns.mhaStatus(patientId),
        apiConfig.patients.getMhaState(patientId, query),
      );
    },

    getMhaDashboardData: function useGetMhaDashboardData(
      params: MhaDashboardDataParams,
    ) {
      // TODO: add pusher channel
      return useApiAxios<MhaDashboardDataResponse>(
        apiConfig.patients.getMhaDashboardData(params),
      );
    },

    getForms: function useFormsGetAll(
      patientId: string,
      query: {
        formTemplateIds?: UUID[];
        statuses?: FormStatus[];
        completedAfter?: string;
        completedBefore?: string;
      },
    ) {
      return usePusherChannelReload<PatientFormsResponse | null>(
        notificationChannelFns.patientTimeline(patientId),
        apiConfig.patients.getForms(patientId, query),
      );
    },

    search: function useSearchPatient(query: PatientIndexQuery) {
      return useApiAxios<PatientIndexSearchResult>(
        apiConfig.patients.search(query),
      );
    },
  },
  drafts: {
    get: function useGetDraft(formDraftId: string) {
      return usePusherChannelReload<GetFormDraftResponse<any> | null>(
        notificationChannelFns.formDraft(formDraftId),
        apiConfig.drafts.get(formDraftId),
      );
    },
    getPdf: function useGetPdfDraft(formDraftId: string, review: boolean) {
      return useApiAxios<string>(apiConfig.drafts.getPdf(formDraftId, review));
    },
  },
  rio: {
    get: function useRiosGet(rioInstanceId: string) {
      return useApiAxios<RioConfiguration | null>(
        apiConfig.rio.instance.get(rioInstanceId),
      );
    },
    search: function useRioSearch(
      query: string,
      limit: number,
      offset: number,
    ) {
      return useApiAxios<{ results: RioConfiguration[]; count: number }>(
        apiConfig.rio.instance.search(query, limit, offset),
      );
    },
  },

  termsAndConditions: {
    get: function useTermsAndConditionsGet(id: UUID) {
      return useApiAxios<TermsAndConditions | null>(
        apiConfig.termsAndConditions.get(id),
      );
    },
    getAll: function useTermsAndConditionsGetAll() {
      return useApiAxios<TermsAndConditionsResponse>(
        apiConfig.termsAndConditions.getAll(),
      );
    },
  },
};

export const logout = () =>
  (window.location.href = `https://${config.auth0.domain}/v2/logout?client_id=${
    config.auth0.clientId
  }&returnTo=${encodeURIComponent(`${config.backendUrl}/api/oauth/logout`)}`);
